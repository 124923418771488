import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { MenuService } from '../services/menu.service';
import { inject } from '@angular/core';
import { IMenuItem } from '@bs24/core/models/menu';

export const SideMenuResolver: ResolveFn<IMenuItem> = (route: ActivatedRouteSnapshot) => {
  const service = inject(MenuService);
  return service.sideMenu(route.data['menuId']);
}


export const MenuResolver: ResolveFn<IMenuItem> = (route: ActivatedRouteSnapshot) => inject(MenuService).loadMenu(route.data['menuId']);
